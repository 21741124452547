import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import SliderReviewsComponent from './slider_reviews_category'

function CreateRatings(p) {
  let ratings = []
  for (let i = 0; i < 5; ++i) {
    if (p.rating > i) {
      ratings.push(<span className="text-xl text-purple mr-2" key={i}>&#9733;</span>)
    } else {
      ratings.push(<span className="text-xl text-diamond-30 mr-2" key={i}>&#9733;</span>)
    }
  }
  return (
    <div className="mt-1 mb-4 text-center">
      {ratings}
    </div>
  )
}

function GoogleReviews(p) {
  //console.log('p: ', p);
  return (
    <div>
      <div className="block sm:hidden">
        <SliderReviewsComponent slides={p} />
      </div>
      <div className="hidden sm:grid sm:grid-cols-3 lg:grid-cols-5 gap-12 sm:gap-6">
        {p.reviewItems.slice(0,5).map((node) => {
          let excerpt = node.text.length > 150 ? node.text.substring(0, 150) + "..." : node.text;
          return (
            <div key={node.author} className="font-poppins bg-diamond-10 p-6 rounded-tl-[24px] rounded-tr-[24px] rounded-bl-[24px]">
              <figure>
                <StaticImage className="mx-auto w-8 h-8 block" src="../images/google_sm.png" alt="Google Review" />
              </figure>
              <CreateRatings rating={node.rating} />
              <div className="prose prose-sm prose-p:font-medium text-diamond-80 leading-snug">
                <p>&ldquo;{excerpt}&rdquo;</p>
              </div>
              <figure className="mt-4">
                {node.image.gatsbyImageData ? <GatsbyImage className="w-10 h-10 mx-auto rounded-full block" image={node.image.gatsbyImageData} alt={node.image.alt ?? ''} />
                : <StaticImage className="mx-auto w-8 h-8 block" src="../images/google_sm.png" alt="Google Review" />
                }
              </figure>
              <p className="mt-4 text-center text-sm font-medium text-diamond-80 leading-snug capitalize">{node.author}</p>
              <p className="text-center text-xs lowercase font-medium text-diamond-80">({node.time})</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const ReviewsCategory = () => (
  <StaticQuery
    query={graphql`
      {
        prismicGoogleReviews {
          data {
            google_reviews {
              time
              text
              rating
              image {
                gatsbyImageData
                alt
              }
              author
            }
            total_reviews
          }
        }
      }
    `} 
    render = { data => (
      <div className="w-full pt-16 pb-16 bg-diamond">
        <div className="w-10/12 mx-auto max-w-screen-xl mb-8 sm:mb-4">
          <div className="flex flex-col text-center justify-center sm:justify-start sm:flex-row gap-3 items-center font-poppins text-white font-semibold text-xl md:text-2xl leading-tight">
            <div className="w-full sm:w-1/12">
              <StaticImage className="mx-auto w-24 h-24" src="../images/guarantee_white.png" alt="Guarantee" />
            </div>
            <div className="w-full sm:w-11/12">
              <h2>We offer a quality &amp; craftsmanship guarantee on all our gear</h2>
            </div>
          </div>
        </div>
        <div className="w-9/12 sm:w-10/12 mx-auto max-w-screen-xl">
          <GoogleReviews reviewItems={data.prismicGoogleReviews.data.google_reviews} />
        </div>
      </div>
    )}
  />
)

export default ReviewsCategory